$primary: #2f873c; //#000000;
$link: #2f873c;
$info: #48983c;

// Import Main CSS file from theme
@import "main";

.menu_custom {
    position: sticky;
    display: inline-block;
    vertical-align: top;
    max-height: 100vh;
    overflow-y: auto;
    top: 60px;
    bottom: 0;
    padding-top: 0px;
  }

.row_about-me {
  display: flex; 
  align-items: center; }

.column_about-me {
  float: left;
  padding: 10px; }

.left {
  flex-basis: 250px; }

.right {
  flex: 1; }

.row_about-me:after {
  content: "";
  display: table;
  clear: both; }

  @media screen and (max-width: 768px) {
    .row_about-me {
      flex-wrap: wrap;
      justify-content: center; }

    .left{
      flex-basis: 100%; }
  }
  
card_body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 1rem;
    font-weight: 400;
    color: #212529;
    text-align: left;
    background-color: #fff;
}

*,
::after,
::before {
    box-sizing: border-box;
}

.form_card {
    margin: 100px auto;
    text-align: center;
}

.card_h1 {
    margin-top: 24px;
    margin-bottom: 15px;
    font-size: 32px;
    color: #151515;
}

.form_card p {
    margin-bottom: 72px;
    font-size: 18px;
    color: #151515;
    margin-top: 0;
}

.back {
    font-weight: 600;
    color: #151515;
    text-decoration: none;
    cursor: pointer;
}

.back:hover {
    color: #007AFF;
}

.back:hover #thank-you {
    fill: #007AFF;
}

.arrow {
    margin-right: 6px;
}

.logo {
    display: inline-flex;
}

.form_success {
    width: 64px;
}

.form_path {
    stroke-dasharray: 1000;
    stroke-dashoffset: 0;
}

.form_path .circle {
    -webkit-animation: dash 2s ease-in-out;
    animation: dash 2s ease-in-out;
}

.form_path .line {
    stroke-dashoffset: 1000;
    -webkit-animation: dash 4.4s ease-in-out forwards;
    animation: dash 4.4s ease-in-out forwards;
}

.form_path .check {
    stroke-dasharray: 700;
    animation-delay: 0;
    -webkit-animation: dash-check 1s ease-in-out forwards;
    animation: dash-check 1s ease-in-out forwards;
}

@-webkit-keyframes dash {
    0% {
        stroke-dashoffset: 1000;
    }

    100% {
        stroke-dashoffset: 0;
    }
}

@keyframes  dash {
    0% {
        stroke-dashoffset: 1000;
    }

    100% {
        stroke-dashoffset: 0;
    }
}

@-webkit-keyframes dash-check {
    from {
        stroke-dashoffset: 700;
    }

    to {
        stroke-dashoffset: 1400;
    }
}

@keyframes  dash-check {
    from {
        stroke-dashoffset: 700;
    }

    to {
        stroke-dashoffset: 1400;
    }
}

.file {
    max-width: 541px;
    margin: 75px auto;
}
.file span {
    text-align: left;
    font-size: 13px;
    font-weight: 600;
    color: #999999;
    margin-bottom: 5px;
    display: inline-block;
    width: 100%;
}
.file-content {
    border: 1px solid #E7E7E7;
    border-bottom: 0 !important;
}
.file p {
    margin-top: 0;
    margin-bottom: 0;
}
.file .file-box {
    padding: 7px 11px;
    border-bottom: 1px solid #E7E7E7;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.file-box-container {
    display: flex;
    align-items: center;
}
.file .file-tag {
    color: #999999;
    font-size: 10px;
    font-weight: 700;
    background: #E7E7E7;
    border-radius: 19px;
    padding: 4px 8px;
}
.file .file-name {
    font-size: 14px;
    font-weight: 500;
    color: #000;
    margin-left: 7px;
    margin-right: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 300px;
    text-align: left;
}
.file .file-size {
    font-size: 13px;
    color: #999999;
}
.file .file-btn {
    padding: 7px 12px;
    letter-spacing: -0.04px;
    font-weight: 600;
    font-size: 14px;
    color: #151515;
    background: #E7E7E7;
    border-radius: 6px;
    text-decoration: none;
}
.file .file-btn:hover {
    background: #E0E0E0;
}
@media (max-width: 576px) {
    .card {
        margin: 30px auto 110px;
    }
    .card-p {
        margin-bottom: 24px !important;
    }
    .file {
        max-width: 90%;
        margin: 45px auto;
    }
    .file-box {
        padding: 14px 11px !important;
        align-items: normal !important;
        flex-direction: column;
    }

    .file-box-container {
        margin-bottom: 9px;
    }

    .file .file-name {
        max-width: 195px;
    }
}

/* ko-fi icon */
.fa-ko-fi::before {
	content: '\f0f4';
	display: inline-block;
	font-size: 1.1em;
	line-height: 0.45em;
	margin: -0.45em 0 0 -0.2em;
	overflow-y: hidden;
	padding-top: 0.45em;
}
.fa-ko-fi::after {
	color: #ff5c5c;
	content: '\f004';
	font-size: 50%;
	font-weight: 900;
	margin: 0.5em 0 0 -2em;
	position: absolute;
}